<script>
import mealTemplateList from './mealTemplateList'


export default {
    components: {mealTemplateList},
}

</script>
<template>
    <div id="meal-template-view-layout">
        <meal-template-list></meal-template-list>
    </div>
</template>
